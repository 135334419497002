import React from 'react';

export default function CatAuthor({ category, author, className, catClassName, readingTime }) {
  return (
    <div className={`flex flex-wrap -ml-4 items-center ${className || ''}`}>
      {category && (
        <div className="pl-4">
          <div className={`cat bg-black text-white text-16px font-medium text-center py-2 px-4 transition duration-150 ${catClassName || ''}`}>{category}</div>
        </div>
      )}
      {author && author.firstName && <div className="select-none cursor-default author text-15px py-2 pl-4 font-medium">By {author.firstName + ' ' + author.lastName}</div>}
      {readingTime && (
        <div className="select-none cursor-default author text-15px font-medium py-2 pl-4">
          <span className="pr-4">|</span> {readingTime}
        </div>
      )}
    </div>
  );
}
