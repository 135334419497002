import React, { Component } from 'react';
import { graphql } from 'gatsby';
import readingTime from 'reading-time';
import Layout from '../components/Layout';
import BlogPreview from '../components/BlogPreview';
import Image from '../components/Image';
import CatAuthor from '../components/CatAuthor';
import SEO from '../components/SEO';
import PostContent from '../components/PostContent';

export default class Post extends Component {
  render() {
    const {
      wpPost,
      allWpPost,
      wp: {
        options: {
          options: {
            blogPostPage: { relatedInsightsHeading },
          },
        },
      },
    } = this.props.data;

    const category = wpPost.categories.nodes[0]?.name || false;
    const fillerPosts = allWpPost.nodes;
    const related = wpPost.blogPost.related || [];
    const rawContent = this.props.data.wpPost.blogPost.content || [];

    const content = rawContent
      .filter((section) => section.__typename === 'WpPost_Blogpost_Content_Text')
      .map((section) => section.text)
      .join('');

    this.readingTime = readingTime(content);

    return (
      <Layout>
        <SEO seo={wpPost.seo} />

        <section className="pad-t">
          <div className="container max-w-5xl">
            <div className="text-card max-w-2xl">
              <h1 className="text-58px text-card__heading" dangerouslySetInnerHTML={{ __html: wpPost.blogPost.blogHero.heading || wpPost.title }} />
              {wpPost.blogPost.blogHero.intro && <p className="text-22px" dangerouslySetInnerHTML={{ __html: wpPost.blogPost.blogHero.intro }} />}
              <CatAuthor category={category} author={wpPost.author} readingTime={this.readingTime.text} className="mt-8" />
              {/* {this.readingTime && this.readingTime.text && <div>{this.readingTime.text}</div>} */}
            </div>
          </div>
        </section>

        {wpPost.blogPost.blogHero.image && (
          <section className="pad-t--xs">
            <div className="container relative">
              <Image data={wpPost.blogPost.blogHero.image} className="w-full" />
              <img src={require('assets/images/blocks/block-3.svg')} className="absolute bottom-0 right-0 transform translate-y-1/2" alt="" />
            </div>
          </section>
        )}

        <section className="blog-content my-12 mt-24 xl:mt-28">
          <div className="container">
            <PostContent content={wpPost.blogPost.content} type="WpPost_Blogpost" />
          </div>
        </section>

        <section className="pad-tb--sm">
          <div className="container">
            <h4 className="text-42px text-center mb-14 font-semibold">{relatedInsightsHeading}</h4>
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 col-gap-6 row-gap-12">
              {related && related.map(({ relatedPost }) => (relatedPost?.id ? <BlogPreview key={relatedPost.id} post={relatedPost} /> : null))}
              {fillerPosts.slice(0, 3 - related.length).map((post) => (
                <BlogPreview key={post.id} post={post} />
              ))}
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}


export const query = graphql`
  query BlogPost($id: String!) {
    wp {
      options {
        options {
          blogPostPage {
            relatedInsightsHeading
          }
        }
      }
    }
    allWpPost(limit: 3, filter: { id: { ne: $id } }) {
      nodes {
        ...Post
      }
    }
    wpPost(id: { eq: $id }) {
      id
      title
      uri
      ...BlogSEO
      categories {
        nodes {
          name
        }
      }
      author {
        id
        firstName
        lastName
      }
      blogPost {
        blogHero {
          heading
          intro
          image {
            ...Image
          }
        }

        content {
          __typename
          ... on WpPost_Blogpost_Content_Text {
            text
          }
          ... on WpPost_Blogpost_Content_Image {
            image {
              ...Image
            }
            caption
          }
          ... on WpPost_Blogpost_Content_Testimonial {
            logo {
              ...Image
            }
            citeName
            citeRole
            quote
          }
        }

        related {
          relatedPost {
            ... on WpPost {
              ...Post
            }
          }
        }
      }
    }
  }
`;
