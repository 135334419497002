import React from 'react';
import Link from './Link';
import Image from './Image';
import CatAuthor from './CatAuthor';
export default function BlogPreview({ post }) {
  const { uri = '', featuredImage, title = '', blogPost = {}, categories = {}, author } = post;
  const category = categories.nodes[0]?.name || false;

  return (
    <Link to={uri} className="flex flex-col flex-auto">
      <div className="top flex-auto">
        <div className="o-ratio o-ratio--blog-preview bg-tertiary">
          <div className="o-ratio__content">
            <Image data={featuredImage} className="object-cover h-full w-full" />
          </div>
        </div>

        <div className="mt-8 mr-6">
          <h3 className="text-24px font-semibold" dangerouslySetInnerHTML={{ __html: title }} />
          {blogPost.previewTeaser && (
            <p className="text-16px mt-4" style={{ lineHeight: '1.74' }}>
              {blogPost.previewTeaser}
            </p>
          )}
        </div>
      </div>

      <CatAuthor category={category} author={author} className="bottom mt-6" />
    </Link>
  );
}
